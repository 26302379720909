import { useState, createContext, ReactElement, useContext } from "react";
import { Nullable } from "../types/utility";
import { getDoc, doc, collection, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { logger } from "../util/logger";
import { ILocation, ILocationDoc } from "../types/events";
import { Collections } from "../types/collections";
import { IUpdateLocationFrom } from "../types/forms";
import GoogleMapService from "../services/GoogleMapService";
import {
  INSTAGRAM_BASE_URL,
  MAX_AGE,
  MIN_AGE,
  TIKTOK_BASE_URL,
} from "../constants/forms";
import { createAgeRange } from "../helpers/eventHelpers";

interface ILocationsProvider {
  children: ReactElement;
}

interface ILocationsContext {
  location: Nullable<ILocation>;
  loading: boolean;
  error: Nullable<string>;
  getLocation: (locationId: string) => void;
  updateLocation: (locationData: IUpdateLocationFrom, id: string, imagePath: Nullable<string>) => void;
}

const defaultLocationData = {
  location: null,
  loading: false,
  error: null,
  getLocation: () => {},
  updateLocation: () => {},
};

export const LocationsContext = createContext<ILocationsContext>({
  location: defaultLocationData.location,
  loading: defaultLocationData.loading,
  error: defaultLocationData.error,
  getLocation: defaultLocationData.getLocation,
  updateLocation: defaultLocationData.updateLocation,
});

export const LocationsProvider = ({ children }: ILocationsProvider) => {
  const [location, setLocations] = useState<Nullable<ILocation>>(null);
  const [loading, setLoading] = useState(defaultLocationData.loading);
  const [error, setError] = useState(defaultLocationData.error);

  // to implement multiple locations, we need to change the locationId parameter to an array of locationIds
  const getLocation = async (locationId: string) => {
    try {
      logger.info("Getting Locations");
      setLoading(true);

      const LocationsDocRef = doc(db, Collections.Locations, locationId);

      const locationDoc = await getDoc(LocationsDocRef);
      const data = locationDoc.data() as ILocationDoc;

      if (!data) {
        throw new Error("No Locations found");
      }

      setLocations({
        ...data,
        area_id: data.area.id.replace(/\s/g, ""),
      } as unknown as ILocation);

      logger.info("Successfully Locations fetched", data);
    } catch (err: any) {
      logger.error("Error getting Locations", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getCords = async (address: string) => {
    try {
      const response = await GoogleMapService.getCordsByLocation(address);

      if (!response.data.results.length) {
        logger.error("Can't get cords, result is empty");
        return;
      }

      return response.data.results[0].geometry.location;
    } catch (err) {
      logger.error("Error in Location store getCords", err);
    }
  };

  const updateLocation = async (
    locationData: IUpdateLocationFrom,
    id: string,
    imagePath: Nullable<string>
  ) => {
    try {
      if (!location) {
        logger.error("Can't get location, data is empty");
        return;
      }

      const { area, ...rest } = location;

      const newData: ILocationDoc = {
        ...rest,
        names: {
          de: locationData.name,
        },
        accessibility: locationData.accessibility,
        updated_at: new Date().getTime(),
        phone: locationData.phone,
        email: locationData.email,
        website: locationData.website,
        manager: locationData.manager,
        gender: locationData.gender,
        holder: locationData.holder,
        contactPerson: locationData.contactPerson,
        mobileNumber: locationData.mobileNumber,
        location_type: locationData.location_type,
        openingTime: locationData.openingTime,
        lgbtqi: locationData.lgbtqi,
        description: locationData.description,
      };

      if (imagePath) {
        newData.image = imagePath;
      }

      if (locationData.area) {
        const locationCollectionRef = collection(db, Collections.Locations);
        newData.area = doc(locationCollectionRef, locationData.area.id);
        newData.area_id = locationData.area.id;
      }

      if (locationData.minAge || locationData.maxAge) {
        const min = locationData.minAge
          ? parseInt(locationData.minAge)
          : MIN_AGE;
        const max = locationData.maxAge
          ? parseInt(locationData.maxAge)
          : MAX_AGE;
        newData.ageRules = {
          min,
          max,
        };
        location.ageRange = createAgeRange(min, max);
        location.isNoAgeRules = false;
      }

      if (locationData.instagram) {
        newData.social = newData.social.filter(
          (item) => item.name !== "instagram"
        );
        newData.social.push({
          name: "instagram",
          url: `${INSTAGRAM_BASE_URL}${locationData.instagram}/`,
        });
      }

      if (locationData.tiktok) {
        newData.social = newData.social.filter(
          (item) => item.name !== "tiktok"
        );
        newData.social.push({
          name: "tiktok",
          url: `${TIKTOK_BASE_URL}${locationData.tiktok}/`,
        });
      }

      const newAddress = `${locationData.street}, ${locationData.postal_code} ${locationData.city}`;
      if (location?.address !== newAddress) {
        const cords = await getCords(newAddress);
        if (cords) {
          newData.address = newAddress;
          newData.cords = { latitude: cords.lat, longitude: cords.lng };
        }
      }

      const collectionRef = collection(db, Collections.Locations);
      await updateDoc(
        doc(collectionRef, id),
        newData as {
          [x: string]: any;
        }
      );
      setLocations({
        ...newData,
      } as unknown as ILocation);
    } catch (err) {
      logger.error("Error in Location store updateLocation", err);
    }
  };

  return (
    <LocationsContext.Provider
      value={{ location, loading, error, getLocation, updateLocation }}
    >
      {children}
    </LocationsContext.Provider>
  );
};

export const useLocations = () => useContext(LocationsContext);
