import React from "react";
import { FilledInputProps, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";

interface InputFieldProps {
  value: string;
  id: string;
  className?: string;
  containerClassNames?: string;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  label: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  startText?: string;
  size?: "small" | "medium";
  multiline?: boolean;
  rows?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const InputField = ({
  value,
  id,
  className = "",
  containerClassNames = "",
  placeholder = "",
  type = "text",
  error = false,
  helperText = "",
  label,
  disabled = false,
  startText = "",
  size = "small",
  multiline = false,
  rows,
  onChange,
  onFocus = () => {},
}: InputFieldProps) => {
  const InputProps: Partial<FilledInputProps> = {};

  if (startText) {
    InputProps.startAdornment = (
      <InputAdornment position="start">{startText}</InputAdornment>
    );
  }
  return (
    <div className={containerClassNames}>
      <TextField
        id={id}
        label={label}
        variant="outlined"
        className={className}
        placeholder={placeholder}
        value={value}
        type={type}
        error={error}
        helperText={helperText}
        disabled={disabled}
        size={size}
        multiline={multiline}
        rows={rows}
        onChange={onChange}
        onFocus={onFocus}
        InputProps={InputProps}
      />
    </div>
  );
};
