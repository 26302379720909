import {
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import CollapsibleRow from "../CollapsibleRow";
import { useTranslation } from "react-i18next";
import { IEvent } from "../../types/events";
import {
  addWeeks,
  differenceInWeeks,
  formatISO,
  isAfter,
  setDay,
  setMinutes,
  setSeconds,
  subWeeks,
} from "date-fns";
import { Nullable } from '../../types/utility';
import { IDateParticipation, IParticipation } from '../../types/participation';
import { useMemo, useState } from 'react';

interface CollapsibleTableProps {
  event: IEvent;
  eventParticipation: Nullable<IParticipation>;
  isPast?: boolean;
  cancelEvent: (date: string) => () => void;
  removeAmendment: (date: string) => () => void;
  toggleUserParticipation: (
    userId: string,
    eventId: string,
    date: string
  ) => () => void;
  changedEventParticipation: (
    dateParticipation: Nullable<IDateParticipation>,
    date: string,
    userId: string
  ) => (event: SelectChangeEvent<string>) => void;
}

const generateEventDayObject = (event: IEvent) => {
  return ({
      date: event.start_date,
      time: event.start_time,
      users: event.users?.length || 0,
    });
};

const generateEventHistory = (event: IEvent, isPast: boolean) => {
  if (event.recurring_event) {
    const days = [];

    const timeArray = event.start_time.split(":");
    const hour = Number(timeArray[0]);
    const minute = Number(timeArray[1]);
    const today = new Date();
    const startDate = new Date(event.start_date);

    let counter = 0;
    const weeks = differenceInWeeks(new Date(event.end_date), startDate);
    while (counter <= weeks) {
      // eslint-disable-next-line no-loop-func
      event.days.map((day => day === 7 ? 0 : day)).forEach((day) => {
        const start_range_date = startDate > today ? startDate : today;
        const targetedWeek = isPast
          ? subWeeks(start_range_date, counter)
          : addWeeks(start_range_date, counter);
        const eventDate = setDay(targetedWeek, day, { weekStartsOn: 1 }).setHours(hour);
        const eventDateWithMinutes = setSeconds(
          setMinutes(eventDate, minute),
          0
        );
        const isDateAfterEndDate = isAfter(
          eventDateWithMinutes.setHours(23),
          new Date(event.end_date)
        );

        const isDateAfterStartDate = isAfter(
          eventDateWithMinutes.setHours(23),
          startDate
        );

        if (!isDateAfterEndDate && isDateAfterStartDate) {
          // if event is in the past and date is after today
          const conditionForPast = isPast && isAfter(eventDateWithMinutes, today);
          // if event is in future and date is after today
          const conditionForFuture = !isPast && isAfter(today, eventDateWithMinutes);
          if (conditionForPast || conditionForFuture) {
            return;
          }
          const isoDate = formatISO(eventDateWithMinutes);
          days.push(generateEventDayObject({ ...event, start_date: isoDate }));
        }
      });
      counter += 1;
    }
    return days;
  } else {
    return [
      generateEventDayObject({ ...event }),
    ];
  }
};

export const CollapsibleTable = ({
  event,
  eventParticipation,
  isPast = false,
  cancelEvent,
  removeAmendment,
  toggleUserParticipation,
  changedEventParticipation,
}: CollapsibleTableProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t } = useTranslation();
  const eventDays = useMemo(() => generateEventHistory(event, isPast), [event, isPast]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t("Date")}</TableCell>
            <TableCell align="right">{t("Registrations")}</TableCell>
            <TableCell align="right">{t("Checkins")}</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventDays
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item) => (
              <CollapsibleRow
                key={item.date}
                row={item}
                event={event}
                eventParticipation={eventParticipation}
                cancelEvent={cancelEvent}
                removeAmendment={removeAmendment}
                toggleUserParticipation={toggleUserParticipation}
                changedEventParticipation={changedEventParticipation}
              />
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={eventDays.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};
