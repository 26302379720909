import { Gender, IArea, ICategory, ILocation, IUserInEvent, StorageImage } from "./events";
import { Nullable } from "./utility";

export type EventFormFields =
  | "name"
  | "category"
  | "description"
  | "gender"
  | "minAge"
  | "maxAge"
  | "recurring_event"
  | "days"
  | "start_date"
  | "end_date"
  | "price"
  | "time"
  | "start_time"
  | "end_time"
  | "lgbtqi"
  | "isSchoolEvent";

export type LocationFormFields =
  | "name"
  | "street"
  | "city"
  | "postal_code"
  | "area"
  | "gender"
  | "minAge"
  | "maxAge"
  | "accessibility"
  | "holder"
  | "phone"
  | "email"
  | "website"
  | "image"
  | "manager"
  | "contactPerson"
  | "mobileNumber"
  | "instagram"
  | "tiktok"
  | "location_type"
  | "openingTime"
  | "lgbtqi"
  | "description";

export interface ICreateEventFrom {
  name: string;
  category: Nullable<ICategory>;
  description: string;
  gender: Gender;
  minAge: string;
  maxAge: string;
  recurring_event: boolean;
  start_date: string;
  end_date: string;
  price: string;
  isSchoolEvent: boolean;
  days: number[];
  location: Nullable<ILocation>;
  start_time: string;
  end_time: string;
  image: StorageImage;
  lgbtqi: boolean;
  usersInEvent: IUserInEvent[];
}

export interface IUpdateLocationFrom {
  name: string;
  street: string;
  city: string;
  postal_code: string;
  area: Nullable<IArea>;
  gender: Gender;
  minAge: string;
  maxAge: string;
  accessibility: boolean;
  holder: string;
  phone: string;
  email: string;
  website: string;
  image: StorageImage;
  manager: string;
  contactPerson: string;
  mobileNumber: string;
  instagram: string;
  tiktok: string;
  location_type: string;
  openingTime: string;
  lgbtqi: boolean;
  description: string;
}

export interface SelectItem {
  id: string;
  label: { de: Nullable<string> };
}

export enum IEventTypes {
  jugendzentrum = "Jugendzentrum",
  theater = "Theater",
  musikschule = "Musikschule",
  tanzstudio = "Tanzstudio",
  kulturverein = "Kulturverein",
  natur = "Natur",
  partei = "Partei",
  berufsorientierung = "Berufsorientierung",
  musikclub = "Musikclub",
  abenteuerspielplatz = "Abenteuerspielplatz",
  makerSpace = "Maker Space",
};
