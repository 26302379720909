import { Gender } from "../types/events";
import { ICreateEventFrom, IUpdateLocationFrom, SelectItem } from "../types/forms";

export const defaultEventFormState = (): ICreateEventFrom => ({
  name: "",
  category: null,
  description: "",
  gender: Gender.All,
  minAge: "",
  maxAge: "",
  recurring_event: true,
  start_date: "",
  end_date: "",
  start_time: "",
  end_time: "",
  price: "free",
  days: [1],
  location: null,
  lgbtqi: false,
  image: {
    path: "",
    url: "",
  },
  isSchoolEvent: true,
  usersInEvent: [],
});

export const defaultLocationFormState = (): IUpdateLocationFrom => ({
  name: "",
  street: "",
  city: "",
  postal_code: "",
  area: null,
  gender: Gender.All,
  minAge: "",
  maxAge: "",
  accessibility: true,
  holder: "",
  manager: "",
  contactPerson: "",
  phone: "",
  mobileNumber: "",
  email: "",
  website: "",
  instagram: "",
  tiktok: "",
  image: "",
  location_type: "",
  openingTime: "",
  lgbtqi: false,
  description: "",
});

export const defaultFormErrorState = () => ({
  name: "",
  description: "",
  start_date: "",
  end_date: "",
  start_time: "",
  end_time: "",
  minAge: "",
  maxAge: "",
});

export const defaultLocationErrorState = () => ({
  name: "",
  street: "",
  city: "",
  postal_code: "",
  area: "",
  gender: "",
  minAge: "",
  maxAge: "",
  accessibility: "",
  holder: "",
  phone: "",
  email: "",
  manager: "",
  contactPerson: "",
  location_type: "",
  openingTime: "",
  description: "",
});

export const transformItemsForSelect = (items: SelectItem[]) => {
  return items.map((item) => ({
    label: item.label.de || "",
    value: item.id,
  }));
};

export const generateSmallRandomId = () => {
  // Convert a random number to base 36 and slice it to get the last 6 characters
  return Math.random().toString(36).slice(-6);
}
