import {
  FileWithPath,
  UseFilePickerConfig,
} from "use-file-picker/dist/interfaces";
import { Validator } from "use-file-picker/validators";
import { toast } from "react-toastify";

/**
 * validateBeforeParsing allows all files to be selected
 * validateAfterParsing allows the user to select only files that on portrait orientation
 */
export class ImageCustomValidator extends Validator {
  mode: "vertical" | "horizontal";

  constructor(mode: "vertical" | "horizontal" = "vertical") {
    super();
    this.mode = mode;
  }
  async validateBeforeParsing(
    config: UseFilePickerConfig<unknown>,
    plainFiles: File[]
  ) {
    return new Promise<void>((res, rej) => {
      if (plainFiles.length) {
        res();
      }
    });
  }
  async validateAfterParsing(
    config: UseFilePickerConfig<unknown>,
    file: FileWithPath,
    reader: FileReader
  ) {
    return new Promise<void>((res, rej) => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = function () {
        const orientationCheck = this.mode === "vertical" ? img.naturalWidth < img.naturalHeight : img.naturalWidth > img.naturalHeight;
        console.log('here', orientationCheck);
        if (orientationCheck) {
          res();
        } else {
          toast.error(this.mode === "vertical" ? "Die Bildbreite sollte kleiner als die Höhe sein" : "Die Bildbreite sollte größer als die Höhe sein");
          rej();
        }
      }.bind(this);
    });
  }
}
