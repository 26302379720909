export const emailRegexp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const supportTeamEmail = "support@meinklub.app";

export const getRegistrationEmail = (email: string) => ({
  subject: "New registration to Location admin",
  text: `Hey hey, a new user has registered to the location admin. Please reach out to ${email} to verify the user.`,
  to: supportTeamEmail,
});

export const getRegistrationSuccessEmail = (email: string) => ({
  subject: "Neu Registrierung bei Klub",
  text: `Hallo,\n\ndeine Registrierung wurde an das Klub-Support-Team gesendet und wird bearbeitet. In den kommenden Tage senden wir dir deine Zugangsaten zu.\n\nBeste Grüße,\nDein Klub-Team`,
  to: email,
});

export const emojiRegex = /[\p{Emoji}\u200d]+/gu;

export const max_query_batch = 30;