import { DocumentData, DocumentReference } from "firebase/firestore";

export interface LocalizationObject {
  [key: string]: string | undefined;
  en?: string;
  de?: string;
}

export enum Collections {
  user = "UserProfile",
  categories = "Categories",
  events = "Events",
  locations = "Locations",
  areas = "Areas",
  settings = "Settings",
  indexing = "EventsIndexing",
}

export interface ICategory {
  id: string;
  label: LocalizationObject;
  value: string;
  order: number;
}

export interface IStatusUpdates {
  areas?: number;
  categories?: number;
  locations?: number;
}

export interface ISocialEvent {
  name: string;
  url: string;
}

export interface ILocation {
  id: string;
  accessibility: boolean;
  address: string;
  area: IArea;
  area_id: string;
  email: string;
  finance: string;
  location_type: string;
  names: LocalizationObject;
  offers: string[];
  phone: string;
  target_group: string[];
  website: string;
  cords: {
    latitude: number;
    longitude: number;
  };
  ageRules?: IAgeRules;
  social: ISocialEvent[];
  quarter: string;
  contactPerson: string;
  gender?: Gender;
  openingTime: string;
  image: string;
  ageRange?: number[] | number;
  isNoAgeRules?: boolean;
  mobileNumber?: string;
  holder?: string;
  manager?: string;
  lgbtqi: boolean;
  description?: string;
}

export interface ILocationDoc {
  area?: DocumentReference;
  id: string;
  accessibility: boolean;
  address: string;
  area_id: string;
  email: string;
  finance: string;
  location_type: string;
  names: LocalizationObject;
  offers: string[];
  phone: string;
  target_group: string[];
  website: string;
  cords: {
    latitude: number;
    longitude: number;
  };
  ageRules?: IAgeRules;
  social: ISocialEvent[];
  quarter: string;
  contactPerson: string;
  gender?: Gender;
  openingTime: string;
  image: string;
  ageRange?: number[] | number;
  isNoAgeRules?: boolean;
  mobileNumber?: string;
  holder?: string;
  updated_at: number;
  manager?: string;
  lgbtqi: boolean;
  description?: string;
}

export interface Distance {
  text: string;
  value: number;
}

export interface IAgeRules {
  min?: number;
  max?: number;
}

export enum AmendmentType {
  cancel = "CANCEL",
}

export interface IUserInEvent {
  avatar: string;
  dates: string[];
  dob: string;
  id: string;
  name: string;
}

export interface IAmendments {
  id: string;
  type: AmendmentType;
  effected_date: string;
  created_at: number;
  updated_at: number;
}
export interface IEvent {
  ageRange?: number[] | number;
  id: string;
  category?: ICategory;
  cost: string;
  start_date: string;
  end_date: string;
  recurring_event: boolean;
  days: number[];
  descriptions: LocalizationObject;
  end_time: string;
  start_time: string;
  image: StorageImage;
  location: ILocation;
  names: LocalizationObject;
  points: number;
  gender?: Gender;
  social?: ISocialEvent[];
  ageRules: IAgeRules;
  isNoAgeRules?: boolean;
  descriptionWithoutEmoji: string;
  location_name: string;
  category_name: string;
  isActive: boolean;
  distance?: Distance;
  swipeScore?: number;
  feed: string[];
  categoryId?: string;
  category_details?: ICategory;
  location_details?: ILocation;
  created_at: number;
  updated_at: number;
  amendments?: IAmendments[];
  lgbtqi: boolean;
  userIds: string[];
  isSchoolEvent?: boolean;
  usersInEvent: IUserInEvent[];
}

export interface IArea {
  id: string;
  label: LocalizationObject;
  value: string;
  order: number;
}

export interface StorageImage {
  path: string;
  url: string;
}

export interface IUserProfile {
  id: string;
  areas: IArea[];
  avatar: StorageImage;
  categories: ICategory[];
  dob: string;
  events: IEvent[];
  friends: IUserProfile[];
  name: string;
  points: number;
  requests: IUserProfile[];
  nameIndexing?: string[];
  gender: Gender;
}

export interface IUserInEvent {
  avatar: string;
  dates: string[];
  dob: string;
  id: string;
  isSchool: boolean;
  name: string;
}

export interface IEventDoc {
  days: { [key: string]: boolean };
  category: DocumentReference;
  location: DocumentReference;
  users: DocumentReference[];
  image: string;
  id: string;
  category_details: ICategory;
  location_details: ILocation;
  usersInEvent?: IUserInEvent[];
}

export interface ICreateEventDTO {
  ageRange?: number[] | number;
  id: string;
  category?: DocumentReference<DocumentData, DocumentData>;
  cost: string;
  start_date: string;
  end_date: string;
  recurring_event: boolean;
  days: { [key: string]: boolean };
  descriptions: LocalizationObject;
  end_time: string;
  start_time: string;
  image: string;
  location: DocumentReference<DocumentData, DocumentData>;
  names: LocalizationObject;
  points: number;
  gender?: Gender;
  social?: ISocialEvent[];
  ageRules: IAgeRules;
  isNoAgeRules?: boolean;
  descriptionWithoutEmoji: string;
  location_name: string;
  category_name: string;
  isActive: boolean;
  distance?: Distance;
  swipeScore?: number;
  feed: string[];
  categoryId?: string;
  category_details?: ICategory;
  location_details?: ILocation;
  created_at: number;
  updated_at: number;
  lgbtqi: boolean;
  isSchoolEvent: boolean;
  usersInEvent: IUserInEvent[];
}

export enum Gender {
  boy = "boy",
  girl = "girl",  
  minta = "minta",
  All = "all"
}
